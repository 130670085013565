
@media screen and (min-width: 768px) and (max-width: 1023px) { 
    .banner-text .pt-5 {
        padding-top: 2.5rem !important;
    }
}

@media screen and (max-width: 767px) {
    .banner-text .pt-5 {
        padding-top: 0.8rem !important;
    }
    .home-banner > img.w-100 {
        height: 250px;
    }
    .banner-text .col-5.ps-3 {
        padding-left: 0rem !important;
        padding-right: 0;
    }
    .banner-text .col-7 {
        padding: 0;
    }
}

@media screen and (min-width: 540px) and (max-width: 767px) {
    .banner-text .pt-5 {
        padding-top: 1.5rem !important;
    }
}