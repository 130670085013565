.Footer {
    .footer-navigation {
        display: grid;
        color: #4B0082;
        font-family: Arial;
        text-decoration: none;
        background-image: url("../../../assets/images/footerbg.svg");
        padding: max(1vh, 1rem) 1rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        font-weight: 700;
        grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
        font-size: var(--font-xxs);
        .footer-navigation-item {
            .footer-contact-info {
                display: inline-flex;
                padding: 0 0.5rem;
            }
            .footer-location-address, .footer-contact-mail, .footer-share-options {
                display: inline-flex;
                padding: max(1vh, 0rem) 0.5rem;
                .address-text {
                    p{
                        margin-bottom: 0;
                    }
                }
            }
            .footer-about-teams {
                padding-left: 6rem;
                padding-top: 0.5rem;
                padding-right: 0.5rem;
                padding-bottom: 0.5rem;
            }
            .footer-share-options {
                text-align: center;
                padding: 0;
                a {
                    font-size: 22px;
                    padding: 0;
                    color: indigo;
                }
            }

            .footer-icons {
                width: 32px;
                padding-right: 8px;
                align-self: flex-start;
            }
            .footer-links {
                text-decoration: none !important;
                color: #4B0082;
                line-break: anywhere;
            }
        }
        .mail-contact {
            display: flex;
            flex-direction: column;
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1399px) {
    .footer-navigation {
        .footer-navigation-item {
            .footer-about-teams {
                padding: max(1vh, 0rem) 0.5rem !important;
            }
            .footer-share-options,
            .only-home-page-content {
                padding: max(0vh, 0rem) 0.5rem !important;
            }
        }
    }

}

@media screen and (max-width: 1023px) {
    .footer-about-teams {
        padding-left: 0.5rem !important;
    }
    .footer-share-options,
    .only-home-page-content {
        padding: max(0vh, 0rem) 0.5rem !important;
    }
}

@media screen and (max-width: 540px) {
    .footer-about-teams {
        text-align: left !important;
        padding-left: 2.5rem !important;
    }
    .footer-contact-info {
        padding: 0 0.5rem 0.5rem !important;
    }
    .footer-share-options, .only-home-page-content {
        padding-left: 2.5rem !important;
    }
    .footer-location-address {
        .address-text {
            p{
                margin-bottom: 0;
            }
        }
    }
    .footer-navigation {
        .footer-navigation-item {
            .footer-icons {
                width: 34px !important;
                padding-right: 12px !important;
            }
        }
    }
}