.info-animation-container {
    padding: 0.7rem 2rem 0rem;
    display: inline-block;
    width: 100%;
}

.default-story-title {
    text-align: justify;
    font-size: clamp(1.3rem, 5vw + 1.7rem, 2rem);
    font-family: 'Bebas Neue';
    margin-bottom: 0.3rem;
}
.default-story-paragraph {
    text-align: justify;
    font-size: clamp(1rem, 5vw + 1.3rem, 1.3rem);
    margin-bottom: 0.5rem;
}

.story-title {
    text-align: justify;
    font-size: var(--font-md);
    font-family: 'Bebas Neue';
    margin-bottom: 0.3rem;
}
.story-paragraph {
    text-align: justify;
    font-size: clamp(0.8rem, 5vw + 0.9rem, 1.12rem);
    margin-bottom: 0.5rem;
    line-height: 1.35;
}

.animation-image {
    width: 100%;
    height: 100%;
}

.animation-image-fullsize {
    width: 75%;
}

.animation-container-parent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.animation-container {
    height: 100%;
}
.animation {
    height: 100%;
}

.story-detail-info {
    padding-top: 0;
    margin-top: 0;
  
}

.icon-prev {
    transform: rotateY(180deg);
}
.icon-prev, .icon-next {
    height: 40px;
    width: 40px;
    margin: 5px;
}
.scissor {
    position: absolute;
    top: -18px;
    right: 10px;
    transform: rotateY(180deg);
}

.animation-image-container {
    width: 40% !important;
    float: right;
    margin-left: 2rem;
    margin-bottom: 0.7rem;
    border: 2px dashed #000;
    position: relative;
}

.animation-image-container-fullsize {
    margin: 1rem 4rem;
    border: 2px dashed #000;
    position: relative;
    text-align: center; 

    .animation-image {
        width: 75%;
    }
}

.animation-image {
    width: 100%;
}

@media screen and (max-width: 820px) {
    .animation-image-container {
        width: 100% !important;
        margin: 1rem 0rem;
        border: 2px dashed #000;
        position: relative;
    }
}

@media screen and (max-width: 540px) {
    .info-animation-container {
        padding: 1rem;
    }
}
