@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
.loading-spinner {
    width: 40px;
    height: 40px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    margin: 0 auto;
}

.spinner-container {
    display: inline-block;
    justify-content: center;
    align-items: center;
    height: 5vmin;
    width: 6rem;
}