.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
p {
  user-select: none;
}
.no-select {
  user-select: auto;
}
img {
  -webkit-user-drag: none;
  user-drag: none;
}