.slide-item {

    height: 180px;
    width: 180px;
    padding: 0.75rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 5px solid #FFFFFF;
    background-color: #FFFFFF;

    .slide-item-name {
        font-family: 'Bebas Neue';
        margin-bottom: 0.15rem;
        font-size: var(--font-sm);
    }

    .slide-story-desc {
        font-family: 'Roboto';
        color: #000;
        line-height: 1.3rem;
        font-weight: 600;
        text-transform: capitalize;
        margin-bottom: 0;
        font-size: var(--font-xs);
    }
}