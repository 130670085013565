.advertisment-slider {
    .advertisement-images {
        width: 100%;
        max-height: 170px !important;
        display: unset;
    } 
}

.ad-icon-prev {
    transform: rotateY(180deg);
}
.ad-icon-prev, .ad-icon-next {
    height: 40px;
    width: 40px;
}

.advertisment-slider {
    .swiper-wrapper {
        .swiper-slide {
            min-height: auto !important;
            display: unset !important;
        }
    }
}

.advertisment-slider {
    .swiper-button-next, .swiper-button-prev {
        right: var(--swiper-navigation-sides-offset, 5px);
        color: #fff;
    }
    .swiper-button-next:after, .swiper-button-prev:after {
        font-size: 2rem;
    }
}