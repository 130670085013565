:root {
  --font-xl: clamp(1.4rem, 5vw + 1.4rem, 4rem);
  --font-lg: clamp(1rem, 5vw + 2.5rem, 3rem);
  --font-md: clamp(1rem, 5vw + 0.5rem, 1.7rem);
  --font-sm: clamp(0.8rem, 1vw + 1rem, 1.3rem);
  --font-xs: clamp(0.8rem, 5vw + 0.9rem, 0.9rem);
  --font-xxs: clamp(0.6rem, 4vw + 0rem, 0.85rem);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
